import React, { useContext, Suspense } from "react";
import { Menu as UikitMenu } from "@pancakeswap-libs/uikit";
import { useWeb3React } from "@web3-react/core";
import { allLanguages } from "config/localisation/languageCodes";
import { LanguageContext } from "contexts/Localisation/languageContext";
import useTheme from "hooks/useTheme";
import useAuth from "hooks/useAuth";
import Landing from "components/custom/Landing";
import {
  // usePriceCakeBusd,
  useProfile,
} from "state/hooks";
import styled from "styled-components";
import Nav from "./Nav";
import config from "./config";

const Container = styled.div`
  margin-top: 80px;
  padding: 0;
`;
const Loading = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  color: #fff;
  font-size: 24px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 0;
  background-color: rgba(1, 1, 1, 0.4);
  border-top: 1px solid darkgrey;
`;

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: white;
  margin: auto;
`;

const Menu = ({ children }) => {
  const { account } = useWeb3React();
  const { login, logout } = useAuth();
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext);
  const { isDark, toggleTheme } = useTheme();
  // const cakePriceUsd = usePriceCakeBusd();
  const { profile } = useProfile();
  return (
    <Container>
      <Nav />
      {/* <Suspense fallback={<Loading>Setting things up...</Loading>}>
   
      </Suspense>
      <Landing/> */}
      {children}
      <Footer>
        <FooterContent className="container" style={{ opacity: 0.7 }}>
          <div>
            © Copyright 2022 Treasure DAO. Dapp built by  <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/HighstackToby"
              style={{ color: "#add8e6" }}
            >
              @TobyO
            </a> 
            {/* This dApp was built with ❤ by{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://t.me/OGtoby"
              style={{ color: "#add8e6" }}
            >
              wizards
            </a>{" "}
            at Treasure DAO */}
          </div>
        </FooterContent>
      </Footer>
    </Container>
    // <UikitMenu
    //   account={account}
    //   login={login}
    //   logout={logout}
    //   isDark={isDark}
    //   toggleTheme={toggleTheme}
    //   currentLang={selectedLanguage && selectedLanguage.code}
    //   langs={allLanguages}
    //   setLang={setSelectedLanguage}
    //   cakePriceUsd={cakePriceUsd.toNumber()}
    //   links={config}
    //   // profile={{
    //   //   username: profile?.username,
    //   //   image: profile?.nft ? `/images/nfts/${profile.nft?.images.sm}` : undefined,
    //   //   profileLink: '/profile',
    //   //   noProfileLink: '/profile',
    //   //   showPip: !profile?.username,
    //   // }}
    //   {...props}
    // />
  );
};

export default Menu;
