import React, { useState } from "react";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { light, dark } from "@pancakeswap-libs/uikit";

const CACHE_KEY = "IS_DARK";

export interface ThemeContextType {
  isDark: boolean;
  toggleTheme: () => void;
}

const ThemeContext = React.createContext<ThemeContextType>({
  isDark: false,
  toggleTheme: () => null,
});
const modTheme = {
  ...dark,
  colors: {
    ...dark.colors,
    primary: "#EAA55A",
    secondary: "#EAA55A",
    borderColor: "#ffbb00",
    background:"#27262c",
    textSubtle: "#EAA55A",
    text: "#fff",
    tertiary: "#fff",
    failure: "#ab3f3d",
    input: "#000000b3",
    success: "#EAA55A",
    navBorder: "#EAA55A",
  },
  shadows: {
    ...dark.shadows,
    focus: "0px 0px 0px 1px #EAA55A, 0px 0px 0px 4px #EAA55A99;",
  },
  nav: {
    ...dark.nav,
    background: "#EAA55A",
  },
  // tooltip:{
  //   ...dark.tooltip,
  //   background:'red'
  // }
  card: { ...dark.card, background: "#EAA55A" },
};

const ThemeContextProvider: React.FC = ({ children }) => {
  const [isDark, setIsDark] = useState(() => {
    const isDarkUserSetting = localStorage.getItem(CACHE_KEY);
    return isDarkUserSetting ? JSON.parse(isDarkUserSetting) : false;
  });

  const toggleTheme = () => {
    setIsDark((prevState: any) => {
      localStorage.setItem(CACHE_KEY, JSON.stringify(!prevState));
      return !prevState;
    });
  };

  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
      <SCThemeProvider theme={modTheme}>{children}</SCThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeContextProvider };
