/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { farmsV2Config } from "config/constants/farms";
import BigNumber from "bignumber.js";
import { fetchFarmsV2 } from "./fetchFarmsV2";

import {
  fetchFarmV2UserEarnings,
  fetchFarmV2UserAllowances,
  fetchFarmV2UserTokenBalances,
  fetchFarmV2UserStakedBalances,
  fetchFarmV2UserUnlockTime
} from "./fetchFarmUserV2";
import { FarmsV2State, FarmV2 } from "../types";

const initialState: FarmsV2State = {
  data: [...farmsV2Config],
  rewardTokenPerBlock: "0",
};

export const farmsV2Slice = createSlice({
  name: "FarmsV2",
  initialState,
  reducers: {
    setFarmsV2PublicData: (state, action) => {
      const liveFarmsData: FarmV2[] = action.payload;
      state.data = state.data.map((farm) => {
        const liveFarmData = liveFarmsData.find(
          (f) => f.lpSymbol === farm.lpSymbol,
        );
        return { ...farm, ...liveFarmData };
      });
    },
    setFarmV2PublicData: (state, action) => {
      const rewardTokenPerBlock: string = action.payload;
      state.rewardTokenPerBlock = rewardTokenPerBlock;
    },
    setFarmV2UserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload;
      arrayOfUserDataObjects.forEach((userDataEl) => {
        const { index } = userDataEl;
        state.data[index] = { ...state.data[index], userData: userDataEl };
      });
    },
  },
});

// Actions
export const { setFarmsV2PublicData, setFarmV2UserData, setFarmV2PublicData } =
  farmsV2Slice.actions;

// Thunks
export const fetchFarmsV2PublicDataAsync = () => async (dispatch) => {
  const farms = await fetchFarmsV2();
  dispatch(setFarmsV2PublicData(farms));
};
export const fetchFarmV2UserDataAsync = (account) => async (dispatch) => {
  let userFarmV2Allowances = [];
  let userFarmV2TokenBalances = [];
  let userStakedBalances = [];
  let userFarmV2Earnings = [];
  let userFarmV2StakeTime = [];
  try {
    userFarmV2Allowances = await fetchFarmV2UserAllowances(account);
  } catch (e) {
    console.log("fetchFarmV2UserAllowances", e);
  }
  try {
    userFarmV2TokenBalances = await fetchFarmV2UserTokenBalances(account);
  } catch (e) {
    console.log("fetchFarmV2UserTokenBalances", e);
  }
  try {
    userStakedBalances = await fetchFarmV2UserStakedBalances(account);
  } catch (e) {
    console.log("fetchFarmV2UserStakedBalances", e);
    throw e
  }
  try {
    userFarmV2Earnings = await fetchFarmV2UserEarnings(account);
  } catch (e) {
    console.log("fetchFarmV2UserEarnings", e);
  }
  try {
    userFarmV2StakeTime = await fetchFarmV2UserUnlockTime(account)
  } catch (e) {
    console.log("fetchFarmV2UserUnlockTime", e);
  }

  const arrayOfUserDataObjects = userFarmV2Allowances.map(
    (farmAllowance, index) => {
      return {
        index,
        allowance: userFarmV2Allowances[index],
        tokenBalance: userFarmV2TokenBalances[index],
        stakedBalance: userStakedBalances[index],
        earnings: userFarmV2Earnings[index],
        stakeTime: userFarmV2StakeTime[index],
      };
    },
  );

  dispatch(setFarmV2UserData({ arrayOfUserDataObjects }));
};

export default farmsV2Slice.reducer;
