import { BigNumber, formatFixed } from "@ethersproject/bignumber";
import BigNumberJS from "bignumber.js";
import erc20 from "config/abi/erc20.json";
import masterchefABI from "config/abi/masterchef.json";
import multicall from "utils/multicall";
import { getAddress } from "utils/addressHelpers";
import { getFarmV2Contract } from "utils/contractHelpers";
import { farmsV2Config } from "config/constants/farms";

export const fetchFarmsV2 = async () => {
  const data = await Promise.all(
    farmsV2Config.map(async (farm, index) => {
      const lpAddress = getAddress(farm.lpAddresses);
      const calls = [
        // Balance of token in the LP contract
        {
          address: getAddress(farm.tokenAddresses),
          name: "balanceOf",
          params: [lpAddress],
        },
        // Balance of BNB token on LP contract
        {
          address: getAddress(farm.quoteTokenAdresses),
          name: "balanceOf",
          params: [lpAddress],
        },
        // Balance of LP tokens in the master chef contract
        {
          address: lpAddress,
          name: "balanceOf",
          params: [getAddress(farm.farmAddress)],
        },
        // Total supply of LP tokens
        {
          address: lpAddress,
          name: "totalSupply",
        },
        // Token decimals
        {
          address: getAddress(farm.tokenAddresses),
          name: "decimals",
        },
        // BNB token decimals
        {
          address: getAddress(farm.quoteTokenAdresses),
          name: "decimals",
        },
      ];

      let [
        tokenBalanceLP,
        quoteTokenBalanceLP,
        lpTokenBalanceMC,
        lpTotalSupply,
        tokenDecimals,
        quoteTokenDecimals,
      ] = await multicall(erc20, calls);

      if (getAddress(farm.tokenAddresses) === lpAddress){
        tokenBalanceLP = [1];
        quoteTokenBalanceLP = [1];

      }
      
      tokenBalanceLP = new BigNumberJS(tokenBalanceLP[0].toString());
      quoteTokenBalanceLP = new BigNumberJS(quoteTokenBalanceLP[0].toString());
      lpTokenBalanceMC = new BigNumberJS(lpTokenBalanceMC[0].toString());
      lpTotalSupply = new BigNumberJS(lpTotalSupply[0].toString());
      tokenDecimals = new BigNumberJS(tokenDecimals[0].toString());
      quoteTokenDecimals = new BigNumberJS(quoteTokenDecimals[0].toString());

      // Ratio in % a LP tokens that are in staking, vs the total number in circulation
      const lpTokenRatio = lpTokenBalanceMC.div(lpTotalSupply);

      // Total value in staking in BNB token value - used to calculate TVL
      const lpTotalInQuoteToken = quoteTokenBalanceLP
        .div(new BigNumberJS(10).pow(18))
        .times(new BigNumberJS(2))
        .times(lpTokenRatio);

      // Amount of token in the LP that are staked (i.e amount of token * lp ratio)
      const tokenAmount = tokenBalanceLP
        .div(new BigNumberJS(10).pow(tokenDecimals))
        .times(lpTokenRatio);

      
      const quoteTokenAmount = quoteTokenBalanceLP
        .div(new BigNumberJS(10).pow(quoteTokenDecimals))
        .times(lpTokenRatio);

      const farmContract = getFarmV2Contract(getAddress(farm.farmAddress));
      const poolInfo = await farmContract.methods.pool().call();
      const rewardTokenPerBlock = new BigNumberJS(poolInfo.perBlockNum)
        .div(new BigNumberJS(10).pow(18))
        .toString();


      return {
        ...farm,
        rewardTokenPerBlock,
        tokenAmount: tokenAmount.toJSON(),
        quoteTokenAmount: quoteTokenAmount.toJSON(),
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        tokenPriceVsQuote: quoteTokenBalanceLP.div(tokenBalanceLP).toJSON(),
        // tokenPriceVsQuote: quoteTokenAmount.div(tokenAmount).toJSON(),
        lpTokenBalanceMC: lpTokenBalanceMC
          .div(new BigNumberJS(10).pow(quoteTokenDecimals))
          .toJSON(),
      };
    }),
  );
  return data;
};

export default fetchFarmsV2;
